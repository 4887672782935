.addMovieContainer{
    margin: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}
@media (max-width: 768px){
    .addMovieContainer{
        margin: 20px;
    }
}