.loaderContainer{
    width: 100vw;
    height: 100vh;
    background-color: #ccc;
    opacity: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.loaderContainer img{
    width: 100px;
}