.LoginPageContainer{
    width: 100vw;
    height: 100vh;
    background-color: #f2e3ce;
}


.LoginWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}