.introContainer{
    background-color: #f2e3ce;
    padding: 50px;
    border-radius: 25px;
}

.introWrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.introWrapper .IntroLeftSection{
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.introWrapper .IntroLeftSection p{
    font-size: 3rem;
    font-weight: 600;
}

.introWrapper .IntroLeftSection a{
    background-color: #df8e1a;
    text-decoration: none;
    font-size: 18px;
    color: white;
    padding: 10px 70px;
    width: fit-content;
    border-radius: 20px;
}

.introWrapper .IntroRightSection img{
    width: 500px;
}

@media (max-width: 768px){

    .introContainer{
        padding: 21px;
    }

    .introWrapper .IntroLeftSection{
        gap: 20px;
    }

    .introWrapper{
        flex-direction: column-reverse;
    }

    .introWrapper .IntroLeftSection p{
        font-size: 20px;
        text-align: center;
    }

    .introWrapper .IntroRightSection img{
        width: 250px;
    }
}